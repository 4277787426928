import * as amplitude from "@amplitude/analytics-browser"
import env from '../../env'

const
    identify = new amplitude.Identify(),
    track = (event, props) => amplitude.track(event, props),
    identity = id => amplitude.setUserId(id),
    props = identify => amplitude.identify(identify),
    initialize = () => {
        amplitude.init(
            env.analytics.amplitude,
            {
                disableCookies: true,
                defaultTracking: false,
                appVersion: env.version
            })
    }

export {
    identify,
    initialize,
    track,
    identity,
    props
}

