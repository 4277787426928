import * as amplitude from "@amplitude/analytics-browser"
import {Stream} from '@republic/foundation/streams'
import {each} from '@republic/foundation/lang/array'
import {isEqual} from '@republic/foundation/lang/is'
import {keys, owns} from '@republic/foundation/lang/object'
import {cleanup} from '../../core/services/cleanup'
import {authenticated, user, info} from '../../auth/streams'
import {subscriber, role} from '../../subscriber/streams'
import {initialize as init, identify} from './amplitude'
import track from './track'
import identity from './identity'
import props from './props'

const
    listeners = {},

    // future analytic platforms wire in here
    initialize = () => {
        init()

        Stream.combineAll([
            authenticated,
            user,
            info,
            role
        ])
        .subscribe(([authenticated, user, info, role]) => {
            const id = amplitude.getUserId()

            if (authenticated && user) {
                if (navigator && navigator.language) {
                    identify.set('lang', navigator.language)
                    props(identify)
                }
                if (!id || id !== user) {
                    identity(user)
                    identify
                        .set('email', info.email)
                        .set('role', role)
                    props(identify)
                    if (!owns(listeners, 'subscriber')) {
                        listeners.subscriber = (
                            subscriber
                            .unique(isEqual)
                            .subscribe(subscriber_id => {
                                if (subscriber_id) {
                                    identify.set('subscriber_id', subscriber_id)
                                    props(identify)
                                }
                            }))
                    }
                }
                track('authenticated')
            }
            else if (!authenticated) {
                identity(null)
            }
        })
    }

cleanup({
    name: 'analytics',
    fn: () => (
        each(
            keys(listeners),
            key => {
                listeners[key]()
                delete listeners[key]
            }))
})

export default initialize
